.loading-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboarding-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 260px;
    max-width: 460px;
    padding: 40px 50px;
    background-color: var(--background-white);
    border-radius: 10px;
    position: relative;
    margin: 30px 0;
}

.onboarding-form-input {
    width: 460px;
    margin: 15px 0 0 !important;
}

.onboarding-title {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    font-weight: 600 !important;
    text-align: center;
}

.onboarding-sub-title {
    margin-bottom: 20px !important;
    text-align: center;
}

.onboarding-information-text {
    margin-bottom: 10px !important;
}

.onboarding-question-text {
    text-align: justify;
}

.onboarding-upload-title {
    font-weight: 600 !important;
}

.onboarding-upload-file {
    display: flex;
    margin-top: 10px;
}

.onboarding-color-picker {
    width: 440px !important;
}

.onboarding-error-text {
    color: #FF0000;
    text-align: center;
}

@media (max-width: 468px) {
    .onboarding-content-wrapper {
        padding: 30px 30px;
        width: 260px;
    }

    .onboarding-form-input {
        width: 260px;
    }

    .onboarding-color-picker {
        width: 240px !important;
    }
}
