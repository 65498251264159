.edit-client--wrapper {
    padding: 30px 40px;
    max-width: 1290px;
    min-width: 320px;
    margin: auto;
}

.edit-client--title {
    margin-bottom: 20px !important;
}

.edit-client--spinner {
    margin: 0 auto;
    text-align: center;
}

.edit-client--fields-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.w100p {
    width: 100%;
}

.edit-client--text-field {
    width: 340px !important;
    margin: 10px 20px 0 0 !important;
}

.edit-client--field-wrapper {
    display: flex;
    align-items: flex-end;
    width: 340px;
    margin: 10px 20px 0 0;
    height: 48px;
}

.edit-client--checkbox {
    padding: 0 !important;
    margin-left: 10px !important;
}

.edit-client--color {
    width: 40px;
    height: 40px;
    margin-left: 10px;
}

.edit-client--image {
    max-width: 240px;
    margin-left: 10px;
    height: 48px;
    object-fit: contain;
}

@media (max-width: 468px) {
    .edit-client--wrapper {
        padding: 0;
    }

    .edit-client--title {
        padding: 20px 0 0 20px;
    }

    .edit-client--text-field {
        width: 300px !important;
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .edit-client--field-wrapper {
        width: 300px;
        margin: 10px 10px;
    }

    .edit-client--image {
        max-width: 200px;
    }
}