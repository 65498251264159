.header-toolbar {
    justify-content: space-between;
}

.header-user-button {
    width: 36px;
    height: 36px;
    border-radius: 18px !important;
    padding: 0;
    min-width: 36px !important;
}
