.table--wrapper {
    padding: 30px 40px;
    max-width: 1290px;
    min-width: 320px;
    margin: auto;
}

.table--title {
    margin-bottom: 20px !important;
}

.table--spinner {
    margin: 0 auto;
    text-align: center;
}

@media (max-width: 468px) {
    .table--wrapper {
        padding: 0;
    }

    .table--title {
        padding: 20px 0 0 20px;
    }
}
