body {
    min-height: 100vh;
    width: 100%;
}

h1 {
    font-size: 28px;
    font-weight: bold;
}

select {
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid #222222;
    border-radius: 0;
}

:root {
    font-family: "Rubik", sans-serif;

    --primary-color: #0073ae;
    --primary-warning: #ff0000;
    --primary-light-gray: #858585;

    --background-primary: #0073ae;
    --background-white: #ffffff;
}

.auth-wrapper {
    min-height: 100vh;
    background-size: cover;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-wrapper.device {
    background-image: url(assets/images/loanAppBG.jpeg);
}

.auth-wrapper.pos {
    background-image: url(assets/images/bg.jpg);
}

.auth-logo-top {
    width: 300px;
    height: 150px;
    position: absolute;
    top: 0;
    right: 0;
}

.auth-logo {
    width: 200px;
    height: 100px;
    top: 0;
    right: 0;
}

.auth-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    max-width: 360px;
    padding: 40px 50px;
    background-color: var(--background-white);
    border-radius: 10px;
    position: relative;
    margin: 30px 0;
}

.auth-title {
    padding-top: 10px;
    font-weight: 600;
}

.auth-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form-input {
    width: 360px;
    margin: 15px 0 0 !important;
}

.auth-form-button {
    margin-top: 20px !important;
    width: 100%;
}

.auth-link-wrapper {
    display: flex;
    padding-top: 30px;
}

.auth-link {
    text-decoration: none;
    color: var(--primary-color);
}

.error-text {
    color: var(--primary-warning) !important;
}

@media (max-width: 468px) {

    .auth-wrapper {
        background-image: none;
        background-color: #47515e;
    }

    .auth-content-wrapper {
        padding: 30px 10px;
        width: 280px;
    }

    .auth-title {
        font-size: 20px;
    }

    .auth-form-input {
        width: 280px;
    }
}