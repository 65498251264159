.data-table--wrapper {
    max-width: 1290px;
    min-width: 320px;
    margin: auto;
}

.data-table--pagination {
    display: flex;
    justify-content: flex-end;
    max-width: 1290px;
    min-width: 320px;
}

.data-table--cell {
    white-space: normal;
    word-break: break-word;
}
