.place-input {
    max-width: 460px;
    margin: 15px 0 0 !important;
    border: none;
    border-bottom: 1px solid black;
    background-color: white;
}

@media (max-width: 468px) {
    .place-input {
        max-width: 260px;
    }
}

.placesResultsContainer p:first-of-type {
    padding-top: 10px;
}

.placesResultsContainer p {
    padding: 4px;
    cursor: pointer;
}

.placesResultsContainer p:hover {
    background-color: #dedede;
}