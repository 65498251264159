.sidebar-wrapper {
    width: 300px;
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.sidebar-header-logo {
    height: 30px;
}
